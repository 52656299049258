/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'gift-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 2.5a2.5 2.5 0 015 0 2.5 2.5 0 015 0v.006c0 .07 0 .27-.038.494H15a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1V4a1 1 0 011-1h2.038A3 3 0 013 2.506zm1.068.5H7v-.5a1.5 1.5 0 10-3 0c0 .085.002.274.045.43zM9 3h2.932l.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 00-3 0zm6 4v7.5a1.5 1.5 0 01-1.5 1.5H9V7zM2.5 16A1.5 1.5 0 011 14.5V7h6v9z"/>',
    },
});
